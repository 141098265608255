.c-section {
  padding: 0;
  overflow: hidden;
  position: relative;
  max-height: 100%;

  @include media-breakpoint-down(lg) {
    &:not(.c-section--detail) {
      display: none !important;
    }

    &.js-is-active {
      display: flex !important;
    }
  }
}

.c-section--detail {
  display: flex;
  flex-direction: column;
}

.c-section--center,
.c-section--right {
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    width: 100%;

    &.js-is-active {
      display: block;
    }
  }
}

.c-section--left {
  @include media-breakpoint-down(lg) {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 30%;
    z-index: 2;
    background-color: $light;
  }

  @include media-breakpoint-down(md) {
    height: 40%;
  }

  @include media-breakpoint-down(sm) {
    height: 37.5%;
  }

  .c-text {
    max-width: 80%;

    @include media-breakpoint-down(xl) {
      max-width: 100%;
    }
  }
}

.c-section--center {
  @include media-breakpoint-down(lg) {
    height: 70%;
  }

  @include media-breakpoint-down(md) {
    height: 60%;
  }

  @include media-breakpoint-down(sm) {
    height: 65%;
  }

  > div {
    display: flex;
  }
}
.c-section--right {
  @include media-breakpoint-down(lg) {
    height: 100%;
  }

  .c-tabs {
    @include media-breakpoint-down(lg) {
      border-bottom: 1px solid $light;
    }
  }
}

@include media-breakpoint-down(lg) {
  .c-section--right .c-section__top {
    display: none;

    .c-video-info {
      margin-left: 63px;
    }
  }

  .mobile-show-stream {
    .c-section--left {
      flex-direction: column;
      display: flex !important;
    }

    .c-section--center {
      display: flex !important;
    }
  }

  .mobile-show-chat,
  .mobile-show-cart,
  .mobile-show-info,
  .mobile-show-wishlist {
    .c-section--right {
      display: flex !important;
    }
  }

  .mobile-show-chat,
  .mobile-show-cart,
  .mobile-show-wishlist {
    .c-section--right .c-section__top {
      display: none;
    }

    .c-section--right .c-section__body {
      display: block;
    }
  }

  .mobile-show-info {
    .c-section--right .c-section__top {
      display: block;
    }

    .c-section--right .c-section__body {
      display: none;
    }
  }
}