.c-stream-finished {
  @include media-breakpoint-down(lg) {
    padding-left: 80px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 65px;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    height: 100%;
  }


  @include media-breakpoint-up(lg) {
    //box-shadow: inset 6px 0 8px -4px rgba($black, .1), inset -6px 0 8px -4px rgba($black, .1);
    border-left: 1px solid $light;
    border-right: 1px solid $light;
  }

  &__msg {
    width: clamp(250px, 70%, 1000px);
  }
}

.stream-finished {
  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
  }

  .c-section--center {
    @include media-breakpoint-down(lg) {
      display: flex !important;
      position: relative;
      height: auto;
    }
  }

  .c-section--right {
    @include media-breakpoint-down(lg) {
      position: relative;
      height: auto;
      flex: 1 1 auto;
    }

    .c-tabs {
      @include media-breakpoint-down(lg) {
        border-bottom: 1px solid $light;
      } 
    }
  }

  .c-shop__top {
    .c-shop-content {
      display: none;;
    }

    h2 {
      margin-bottom: .5rem !important;
    }
  }

  .c-shop__bottom {
    &::after {
      //display: none;
    }

  }
}

.c-stream-finished__footer {
  padding-bottom: .75rem;

  @include rfs(14px);

  img {
    width: clamp(100px, 30%, 130px);
    animation: bouncing-right 2s infinite;
  }
}

@keyframes bouncing-right {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
    opacity: 1;
  }

  40% {
    transform: translateX(-15px);
    opacity: .5;
  }

  60% {
    transform: translateX(-7px);
    opacity: 1;
  }
}

.c-stream-finished__header {
  @include media-breakpoint-down(lg) {
    align-items: center;

    .c-circle {
      margin-right: 1rem;
    }

    h2 {
      @include rfs(24px);

      margin: 0;
    }
  }
}

.c-stream-finished__msg {
  @include media-breakpoint-down(lg) {
    h2 {
      @include rfs(18px);
      margin-bottom: .5rem;
    }

    p {
      margin-bottom: .5rem;
      line-height: 1.2;
    }
  }
}