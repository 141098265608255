.c-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.c-sidebar-nav-item {
  background-color: $light;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 3px;

  @include media-breakpoint-down(sm) {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin-bottom: 1px;
  }

  &--disabled {
    a {
      pointer-events: none;
      cursor: not-allowed;
      opacity: .5;

      svg {
        opacity: .25;
      }
    }
  }

  .js-is-active {
    background-color: $yellow;

    .c-cart-counter, .c-wishlist-counter {
      background-color: $white;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  svg {
    fill: $base;
  }
}