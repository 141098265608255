.c-text {
  ol, ul {
    margin: 1.5rem 0;
  }

  li {
    padding-left: 1.5rem;
    position: relative;
    list-style: none;
  }

  ol li {
    counter-increment: my-custom-counter;

    &::before {
      content: counter(my-custom-counter)'.';
      color: $primary;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  ul li {
    &::before {
      content: '';
      position: absolute;
      left: 5px;
      top: 8px;
      background-color: $primary;
      width: 5px;
      height: 5px;
      display: block;
      border-radius: 50%;
    }
  }
}