.c-chat-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 0;

  @include media-breakpoint-down(lg) {
    padding-left: 80px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 65px;
  }

  &.unregistered {
    filter: blur(3.5px);
  }
}

.c-chat-bottom {
  z-index: 100;

  > .row {
    border-top: 1px solid $gray-200;
  }

  .form-input {
    border: none;
    padding-left: 0;
    // @include rfs(14px);
    height: 37px;
    min-height: 37px;
    max-height: 145px;
    resize: none;

    @include media-breakpoint-only(lg) {
      @include rfs(13px);
    }
  }

  &.unregistered {
    filter: blur(3.5px);
  }

  &.disabled {
    pointer-events: none;
    opacity: .25;
  }
}

.c-chat-item {
  margin-bottom: 1rem;

  .c-balloon {
    position: relative;
    z-index: 10;
  }

  .actions {
    position: relative;
    z-index: 9;
    margin-top: -3rem;
    margin-bottom: 1rem;
    display: flex;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
    opacity: 0;

    button {
      margin: 0 0.25rem;
      border: none;
      background-color: $gray-100;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-in-out;

      &:hover {
        transform: scale(1.1);
        background-color: $gray-200;
        color: $white;
        margin-bottom: 0;
      }
    }
  }

  &:hover {
    .actions {
      margin-top: 0.5rem;
      pointer-events: all;
      opacity: 1;
    }
  }
}

.c-chat-item--removed {
  opacity: 0.5;
}

.c-chat-person {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @include rfs(14px);

  @include media-breakpoint-down(xxl) {
    width: 35px;
    height: 35px;
  }
}

.c-chat-body--scroll-smooth {
  scroll-behavior: smooth;
}

.emoji-picker-wrapper {
  position: absolute;
  bottom: calc(100% + 1rem);
  right: 0;
}

.c-chat-register {
  position: absolute;
  background-color: $white;
  padding: 1.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  box-shadow: 0px 0px 16px 3px rgba($black, 0.15);

  @include media-breakpoint-down(sm) {
    margin-left: 1.5rem;
    max-width: 80%;
  }
}
