.c-balloon {
  background-color: $white;
  box-shadow: 0 7px 16px rgba(0, 0, 0, 0.07);
  display: inline-block;
  padding: 1rem;
  white-space: pre-line;
  position: relative;
  word-break: break-word;

  @include media-breakpoint-only(lg) {
    padding: 0.5rem 0.8rem;
  }

  &.left {
    border-radius: 0 10px 10px 10px;
  }

  &.bg-light {
    border-radius: 10px 0 10px 10px;
    box-shadow: none;
  }

  p {
    @include rfs(14px);

    @include media-breakpoint-only(lg) {
      @include rfs(13px);
    }
  }
}
