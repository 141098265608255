.c-cart-icon {
  position: relative;
  display: inline-block;
}

.c-wishlist-icon {
  position: relative;
  display: inline-block;

  svg {
    width: 26px;
    height: 24px;
  }
}

.c-cart-counter, .c-wishlist-counter {
  background: $yellow;
  color: $base;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 3px;
  top: -8px;
  z-index: 2;

  @include rfs(10px);
}

.c-sidebar .c-cart-counter {
    right: -8px;
    top: 8px;
}

.c-sidebar .c-wishlist-counter {
  right: -9px;
  top: 11px;
}

.cart-item-remove {
  position: absolute;
  right: -15px;
  top: 0;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s $default-transition-timing-function svg {
    fill: $base;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: $light;
  }
}

.c-cart-list__item {
  .c-original {
    text-decoration: line-through;

    @include rfs(13px);
  }
}

.cart-number-input {
  font-size: 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;

  input[type="number"] {
    display: inline-block;
    background-color: $white;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    max-width: 100%;
    min-height: 1.5rem;
    min-width: 8rem;
    padding: 0.4375rem 3rem;
    width: 100%;
    text-align: center;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .vue-number-input__button {
    background-color: $white;
    bottom: 1px;
    position: absolute;
    top: 1px;
    width: 2.5rem;
    z-index: 1;
    border: 1px solid $border-color;
    transition: border-color 0.3s $default-transition-timing-function,
      background-color 0.3s $default-transition-timing-function;

    &.disabled {
      opacity: 0.35;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      border: 1px solid $base;
      background-color: $base;

      &::before,
      &::after {
        background-color: $white;
      }
    }

    &::before,
    &::after {
      content: "";
      background-color: $base;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.3s $default-transition-timing-function;
    }

    &::before {
      height: 1px;
      width: 30%;
    }

    &--minus {
      left: 1px;
    }

    &--plus {
      right: 1px;

      &::after {
        height: 30%;
        width: 1px;
      }
    }
  }
}

.c-cart-list,
.c-wish-list,
.c-cart-footers,
.c-cart-text {
  @include media-breakpoint-down(lg) {
    padding-left: 80px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 65px;
  }
}

.c-cart-list__item__details__title {
  padding-right: 40px;
  line-height: 1.2;

  @include rfs(15px);
}

.c-cart-list__item__data {
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 430px) {
    align-items: flex-end;
    flex-direction: row;
  }

  @include media-breakpoint-up(lg) {
    align-items: flex-start;
    flex-direction: column;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    align-items: flex-end;
  }

  &__price {
    @include media-breakpoint-between(md, xl) {
      margin-top: 1rem;
    }

    @media (max-width: 429px) {
      margin-top: 0.5rem;
      margin-left: auto;
    }
  }

  &__quantity {
    display: flex;
    align-items: flex-end;
  }
}

.cart-item-img {
  @media (max-width: 429px) {
    max-width: 90px;
  }
}

.c-cart-list, 
.c-wish-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 0;

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid $gray-200;
    }
  }

  .swiper-slide:not(:last-child) {
    margin-bottom: .5rem;
  }
}

.c-cart-footer {
  h4 {
    @include media-breakpoint-up(md) {
      @include rfs(20px);
    }
  }
}