.form-input {
  height: 45px;
  padding: 0.5rem 1rem;

  &:focus {
    border-style: solid;
    border-color: $primary;
    outline: $primary;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: $gray-300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray-300;
}

::placeholder {
  color: $gray-300;
}
