.c-section--detail {
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: translateX(0);
  z-index: 6;
  background-color: $white;
  flex: 0 0 auto;
  width: 100%;
  transition: transform 0.3s $default-transition-timing-function;

  @include media-breakpoint-down(lg) {
    box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.011),
      0px 0px 5.3px rgba(0, 0, 0, 0.016), 0px 0px 10px rgba(0, 0, 0, 0.02),
      0px 0px 17.9px rgba(0, 0, 0, 0.024), 0px 0px 33.4px rgba(0, 0, 0, 0.029),
      0px 0px 80px rgba(0, 0, 0, 0.04);
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

.c-section--detail {
  .c-section__body {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.article-detail {
  &__btn-back {
    position: sticky;
    top: 0;
    z-index: 11;

    &__inner {
      position: relative;
      padding: 1rem 0;

      &::before {
        content: "";
        position: absolute;
        margin-left: 50%;
        top: 0;
        bottom: 0;
        background-color: $white;
        z-index: -1;
        left: -50%;
        width: 100%;
      }
    }

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid $gray-300;
      border-left: 2px solid $gray-300;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
      transition: border-color 0.3s $default-transition-timing-function;
    }

    &:hover {
      &::after {
        border-color: $base;
      }
    }
  }

  // Slider
  .swiper-slide {
    height: 100%;
    display: flex;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-article {
    height: auto;
    width: 100%;
    overflow: hidden;
    border: 1px solid $light;
    user-select: none;

    .swiper-button-prev,
    .swiper-button-next {
      background-color: $white;
      width: 35px;
      height: 55px;
      top: calc(50% - 23px);
      animation: fadeIn both 0.3s;
      box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0), 0px 0px 5.3px rgba(0, 0, 0, 0),
        0px 0px 10px rgba(0, 0, 0, 0), 0px 0px 17.9px rgba(0, 0, 0, 0),
        0px 0px 33.4px rgba(0, 0, 0, 0), 0px 0px 80px rgba(0, 0, 0, 0);

      &::after {
        font-size: 16px;
        transition: color 0.3s $default-transition-timing-function;
      }

      &.swiper-button-disabled {
        display: none;
      }

      &:hover {
        color: $base;
      }
    }

    .swiper-button-prev {
      left: 15px;
      border-radius: 0 25px 25px 0;

      &::after {
        transform: translateX(-4px);
      }
    }

    .swiper-button-next {
      right: 15px;
      border-radius: 30px 0 0 30px;

      &::after {
        transform: translateX(4px);
      }
    }
  }
}

.article-detail-options {
  &__item {
    input {
      display: none;

      &:checked + label {
        border-color: $base;
      }
    }

    label {
      border: 1px solid $light;
      cursor: pointer;
      transition: border-color 0.3s $default-transition-timing-function;
      margin-bottom: 0;

      &:hover {
        border-color: rgba($base, 0.25);
      }

      &.fallback {
        background-color: $gray-100;
      }

      &.fallback img {
        opacity: 0;
      }
    }
  }

  &--sizes {
    .article-detail-options__item {
      label {
        padding: 0.3rem 0.5rem;
        font-weight: bold;
      }
    }
  }
}

.article-detail__form {
  &__select {
    border: $light;
    background-color: $light;
  }
}

.article-detail__usps {
  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding: 0 0 0 25px;
      position: relative;

      @include rfs(14px);

      &:not(:last-of-type) {
        margin-bottom: 0.25rem;
      }

      &::before {
        content: "";
        display: block;
        width: 14px;
        height: 7px;
        border-bottom: 2px solid $success;
        border-left: 2px solid $success;
        transform: rotate(-45deg);
        position: absolute;
        left: 3px;
        top: 4px;
        background: none;
        margin: 0;
      }
    }
  }
}

.article-detail__descr {
  h3 {
    @include rfs(14px);
  }

  &__text {
    margin-left: 1.75rem;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  dt {
    font-weight: $font-weight-base;
    max-width: 120px;
    flex: 0 0 120px;
    margin-bottom: .5rem
  }

  dd {
    flex: 0 0 calc(100% - 120px);
    margin-bottom: .5rem
  }

  .washing-sprite {
    transform: rotate(180deg) scaleX(-1);
    width: 32px;
    height: 32px;
  }
}

.productDetail-enter-active, 
.productDetail-leave-active {
  transition: transform .3s $default-transition-timing-function;
}

.productDetail-enter-from,
.productDetail-leave-to {
  transform: translateX(100%);
}
