.animation-pulse {
  animation: animation-pulse 0.6s ease-out;
}

@keyframes animation-pulse {
  0% {
    transform: scale(1) rotate(0deg);
  }
  60% {
      transform: scale(4) rotate(30deg);
  }
  70% {
      transform: scale(0.7) rotate(-30deg);
  }
  80% {
      transform: scale(0.8) rotate(20deg);
  }
  100% {
      transform: scale(1) rotate(0deg);
  }
}

@keyframes animation-pulse-small {
  0% {
    transform: scale(1) rotate(0deg);
  }
  60% {
      transform: scale(1.2) rotate(30deg);
  }
  70% {
      transform: scale(0.9) rotate(-30deg);
  }
  80% {
      transform: scale(0.95) rotate(20deg);
  }
  100% {
      transform: scale(1) rotate(0deg);
  }
}

@keyframes animation-pulse-reverse {
  0% {
    transform: scale(1);
    background-color: primary;
  }
  60% {
    transform: scale(.6);
    background-color: $gray-100;
  }
  70% {
    transform: scale(1.1);
    background-color: $gray-100;
  }
  80% {
    transform: scale(0.95);
    background-color: $gray-100;
  }
  100% {
    transform: scale(1);
    background-color: $gray-100;
  }
}