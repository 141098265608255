/*
 *  Project Settings
 *  ---
 *  Tweak default variables from '_settings.default.scss' to suit your project needs
 */

$enable-responsive-font-sizes: true;
$grid-gutter-width: 30px;

// Colours
$blue: #dfe6fe;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #fedfdf;
$red: #ff4f4f;
$orange: #fd7e14 !default;
$yellow: #ffc107;
$green: #79bc38;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$white: #fff !default;

$gray-100: #f2f2f2;
$gray-200: #dcdcdc;
$gray-300: #aeacac;
$gray-400: #646e7c;
$gray-500: #4d4d4d;

$black: #000;
$base: $gray-500;

$primary: $yellow;
$primary-hover: #eeb700;
$secondary: $orange !default;
$secondary-hover: $yellow;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-500 !default;

// Theme colours
$theme-colors: (
  (
    "blue": $blue,
    "red": $red,
    "orange": $orange,
    "primary": $yellow,
    "danger": $danger,
    "green": $green,
    "cyan": $cyan,
    "light": $light,
    "dark": $dark,
    "pink": $pink,
    "gray-light": $gray-100,
    "teal": $teal,
    "indigo": $indigo,
  )
);

// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $gray-500;

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: $secondary;
$link-hover-decoration: underline;

// General
$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $gray-300;

$border-radius: 50%;
$border-radius-lg: 50%;
$border-radius-sm: 2px;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;
$default-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);

// Font settings
$font-family-base: CircularTT, Tahoma, sans-serif;
$light-font-family: CircularTT, Tahoma, sans-serif;
$medium-font-family: CircularTT, Tahoma, sans-serif;

// Font weights
$font-weight-base: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

// Heading settings
$headings-font-family: $font-family-base;
$headings-font-weight: 700;
$headings-line-height: 1;

$h1-font-size: 80px;
$h2-font-size: 40px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 22px;
$h6-font-size: 20px;

// Forms
$label-font-family: $headings-font-family;
$label-color: $gray-500;
$label-margin-bottom: 0.5rem;

$input-border-radius: 0;
$input-border-color: $border-color;
$input-border-width: $border-width;
$input-bg: $white !default;
$input-color: $gray-500;
$input-placeholder-color: $gray-300 !default;

$input-font-family: $font-family-base;
$input-font-size: 16px;
$input-font-size-lg: 16px;
$input-font-size-sm: 14px;

$input-padding-y: 0.8rem;
$input-padding-x: 1rem;
$input-padding-y-lg: 0.8rem;
$input-padding-x-lg: 1rem;
$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 0.5rem;

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;

$input-btn-font-size: 16px;
$input-btn-font-size-lg: 15px;
$input-btn-font-size-sm: 14px;

// Buttons
$btn-padding-y: 0.8rem !default;
$btn-padding-x: 1.5rem !default;
$btn-padding-y-lg: 0.8rem !default;
$btn-padding-x-lg: 1.5rem !default;
$btn-padding-y-sm: 0.5rem !default;
$btn-padding-x-sm: 1rem !default;

$btn-font-size: 16px;
$btn-font-size-sm: 14px;

$btn-font-family: $font-family-base;
$btn-font-weight: $font-weight-bold;
$btn-border-width: 0;
$btn-border-radius: 0;

// Custom spacers
// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  //4
  2:
    (
      $spacer * 0.5,
    ),
  //8
  3: $spacer,
  //16
  4:
    (
      $spacer * 1.5,
    ),
  //24
  5:
    (
      $spacer * 3,
    ),
  //48
  6:
    (
      $spacer * 4.5,
    ),
  //72
  7:
    (
      $spacer * 6,
    ),
  //96
  8:
    (
      $spacer * 7.5,
    ),
  //120
  9:
    (
      $spacer * 9,
    ),
  //144
  10:
    (
      $spacer * 10.5,
    )
    //168,,,
);

// Modal
$modal-sm: 400px;
