.c-video-info {
  padding: 35px 0;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-left: 80px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 65px;
  }

  @include media-breakpoint-up(lg) {
    background-color: $light;
  }

  &__header {
    padding: 1rem 0;
    position: relative;
    display: inline-block;
    color: base;
    font-weight: $font-weight-bold;
    overflow: hidden;
    transition: color 0.3s $default-transition-timing-function;

    @include rfs(18px);
  }

  .btn-close {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 1rem;
    right: 1rem;

    @include media-breakpoint-down(xxl) {
      top: 0.5rem;
      right: 0.5rem;
    }

    @include media-breakpoint-only(xl) {
      top: 0;
      right: 0;
    }
  }

  h3 {
    padding-right: 1.5rem;

    @include media-breakpoint-down(xl) {
      padding-right: 1rem;
    }
  }
}

.c-video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  @include media-breakpoint-down(lg) {
    margin-left: 80px;
    margin-right: 80px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 360px) {
    margin-right: 15px;
  }
}

.c-video {
  width: 100%;

  @include media-breakpoint-down(lg) {
    max-width: 400px;
    margin: 0 auto;
  }

  @include media-breakpoint-down(sm) {
    max-width: 300px;
  }

  .c-content {
    @include media-breakpoint-down(lg) {
      max-width: 90%;
      margin: 0 auto;
    }

    @include media-breakpoint-down(sm) {
      max-width: 75%;
    }
  }

  .icon-play {
    position: absolute;
    top: 0;
    right: -130px;
    width: 365px;
    height: 365px;
    z-index: 1;

    @include media-breakpoint-down(xl) {
      top: -50px;
    }

    @include media-breakpoint-down(sm) {
      width: 250px;
      height: 250px;
      right: -100px;
    }
  }
}

.c-video-yt {
  width: 100%;
  height: 100%;
  opacity: 1;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.c-video--custom-mute-btn {
  background: none;
  color: $white;
  border: none;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 56px;
  bottom: 0;

  svg {
    fill: white;
    width: 40px;
    padding-left: 4px;
  }
}

.c-circle {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 2;

  img {
    object-fit: cover;
    object-position: center center;
  }

  &--big {
    height: 200px;
    width: 200px;

    @include media-breakpoint-down(xl) {
      width: 150px;
      height: 150px;
    }
  
    @include media-breakpoint-down(sm) {
      width: 70px;
      height: 70px;
      flex-shrink: 0;
    }
  }
}