/*
 *  Elements: page
 *  ---
 *  Simple page-level setup.
 */

/**
 * 1. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  min-height: 100%; /* [1] */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $font-family-base;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: $gray-500;
}

main {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}

header,
main,
footer {
  transition: all 0.3s ease-in-out;
}
