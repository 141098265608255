/*
 * Settings
 * used with preprocessors and contain font, colors definitions, etc.
 */
@import "utilities/utilities.fonts";

@import "settings/settings.project";

// Include swiper css
@import "./node_modules/swiper/swiper-bundle";

//Custome bootstrap selection
@import "utilities/utilities.bootstrap";

//We need to encapsule all our css because this can only be used by the popup!
.liveShopping {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transform-origin: center center;
  transform: scale(1);
  z-index: 100000;

  &.anim {
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  }

  &.to-open {
    transform: scale(0);
    opacity: 0;
  }

  /*
 * Components
 * Specific UI components. This is where majority of our work takes place and
 * our UI components are often composed of Objects and Components
 */
  @import "components/components.article-detail";
  @import "components/components.buttons";
  @import "components/components.form-elements";
  @import "components/components.sections";
  @import "components/components.hamburger";

  @import "components/components.header";
  @import "components/components.footer";
  @import "components/components.logo";
  @import "components/components.mobile-menu";
  @import "components/components.mainmenu";
  @import "components/components.modal";

  @import "components/components.cart";
  @import "components/components.finished-message";
  @import "components/components.chat";
  @import "components/components.card";
  @import "components/components.shop";
  @import "components/components.speaker";
  @import "components/components.video";
  @import "components/components.sidebar";
  @import "components/components.tabs";
  @import "components/components.balloon";
  @import "components/components.swiper";

  @import "components/components.animations";

  /*
 * Elements
 * Some custom styling elements
 */
  @import "elements/elements.headings";
  @import "elements/elements.page";
  @import "elements/elements.paragraphs";
  @import "elements/elements.lists";
  @import "elements/elements.blockquote";
  @import "elements/elements.container";
  @import "elements/elements.images";
  @import "elements/elements.admin";

  /*
 * Utilities
 *
 */
  //@import 'utilities/utilities.breakpoints';
  @import "utilities/utilities.colors";
}

#liveStreamShopping {
  position: fixed;
top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.jbcls__modal_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.45);
}

.jbcls__modal {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  height: 90%;
  transform: translateX(-50%) translateY(-50%);
}