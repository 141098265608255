.c-hamburger {
  width: 30px;
  height: 22px;
  cursor: pointer;
  margin: 0 10px 0 20px;
  position: relative;
  display: none;

  @include media-breakpoint-down(lg) {
    display: inline-block;
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: $black;
    opacity: 1;
    right: 0;
    transition: all .25s ease-in-out 0s;
    transform: rotate(0deg);

    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-child(2) {
      margin-top: 10px;
    }

    &:nth-child(3) {
      margin-top: 20px;
    }
  }

  &.is-open span {
    &:nth-child(1) {
      transform: rotate(135deg);
      margin-top: 10px;
    }

    &:nth-child(2) {
      opacity: 0;
      right: -75px;
    }

    &:nth-child(3) {
      transform: rotate(-135deg);
      margin-top: 10px;
    }
  }
}
