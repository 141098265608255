.c-tabs {
  border-bottom: 1px solid $gray-200;

  @include media-breakpoint-down(lg) {
    padding-left: 80px;
    border: none;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 65px;
  }
}

.c-tabs-item {
  padding: 1rem 0;
  position: relative;
  color: rgba($base, .5);
  font-weight: $font-weight-bold;
  transition: color .3s $default-transition-timing-function;
  display: none;  
  @include rfs(16px);


  @include media-breakpoint-only(lg) {
    @include rfs(16px);
  }

  @include media-breakpoint-up(lg) {
    display: inline-block;
  }

  .c-cart-icon, .c-wishlist-icon {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 1px 1px 0 0;
    background-color: rgba($base, .25);
    position: absolute;
    left: 0;
    transition: transform .5s $default-transition-timing-function, opacity .3s $default-transition-timing-function, background-color .3s $default-transition-timing-function;
    transform: translate3d(0, 4px, 0);
    bottom: 0;
    opacity: 0;
    transition: $transition-base;

    @include media-breakpoint-down(lg) {
      content: unset;
    }
  }

  &.inactive {
    color: $gray-200;
    pointer-events: none;
  }

  &.js-is-active {
    font-weight: $font-weight-bold;
    color: $base;
    display: inline-block;
  
    &::after {
      background-color: $base;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $base;

    &::after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.c-shop-tabs .c-tabs-item {
  display: inline-block;

  @include media-breakpoint-down(lg) {
    padding: 1rem 0;

    &::after {
      content: '';
    }
  }

  @include media-breakpoint-down(sm) {
    @include rfs(14px);
    padding: .5rem 0;
  }

  @media (max-width: 360px) {
    @include rfs(14px);
  }
}

.c-tabs-content {
  display: none !important;

  &.js-is-active {
    display: flex !important;
  }

  .c-text {
    max-width: 400px;
    margin: 0 auto;
  }
}

#tab-chat {
  height: calc(100% - 80px); // chat bottom height

  @include media-breakpoint-down(lg) {
    height: calc(100% - 50px);
  }
}

#tab-cart {
  height: calc(100% - 80px); // chat bottom height

  @include media-breakpoint-down(lg) {
    height: calc(100% - 50px);
  }
}

#tab-wishlist {
  height: 100%;
}
