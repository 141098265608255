.alert-admin {
  border-radius: 0;
}

select.form-control {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.41 8.58997L12 13.17L16.59 8.58997L18 9.99997L12 16L6 9.99997L7.41 8.58997Z' fill='currentColor'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 95% 50%;
  height: auto;
}
