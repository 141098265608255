.swiper.c-shop-list {
  @include media-breakpoint-down(lg) {
    overflow: scroll;
    scroll-snap-type: x mandatory;
    padding-left: 1rem;
    -webkit-mask-image: linear-gradient(90deg,transparent,$light 5%,#000 95%,transparent);
    mask-image: linear-gradient(90deg,transparent,$light 5%,#000 95%,transparent);

    &.inactive {
      overflow-x: hidden;
    }

    /* margin-left: 50px;
    padding-top: 5px;
    padding-bottom: 15px;
    width: 100%;

    position: absolute;
    top: 50%;
    transform: translateY(-50%); */
  }

  @include media-breakpoint-down(sm) {
    /* margin-left: 0;
    margin-right: 0; */
  }

  .swiper-wrapper {
    align-items: stretch;

    @include media-breakpoint-up(lg) {
      width: auto;
      height: auto;
      display: block;
    }
  }
  
  .swiper-slide {
    height: auto;

    @include media-breakpoint-down(lg) {
      scroll-snap-align: start;
      padding-left: 1rem;
      width: 80%;

      &:last-of-type {
        padding-right: 1rem;
      }
    }

    @include media-breakpoint-down(md) {
      width: 85%;
    }

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
  }
  
  .swiper-slide-next {
    position: relative;
  
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $gray-100;
    }
  }
  
  .swiper-horizontal > .swiper-scrollbar {
    width: 100%;
    left: 0;
  }
}

.c-shop__top__inner {
  @include media-breakpoint-up(lg) {
    overflow: auto;
    max-height: 350px;
  }
}