/*
 *  Elements: Headings
 *  ---
 *  We have all of our heading font sizes defined here. Passing these pixel
 *  values into our `rfs()` mixin will generate a fluid font-size
 */
h1, h2, h3, h4, h5, h6 {
  font-family: $headings-font-family;
  font-weight: $font-weight-normal;
}

h1, .h1 {
  @include rfs($h1-font-size);
  line-height: 1.1;

  @include media-breakpoint-down(xxl) {
    @include rfs(70px);
  }

  @include media-breakpoint-down(xl) {
    @include rfs(65px);
  }

  @include media-breakpoint-down(md) {
    @include rfs(45px);
  }

  @include media-breakpoint-down(sm) {
    @include rfs(36px);
  }
}

h2, .h2 {
  @include rfs($h2-font-size);
  line-height: 1;
  margin-bottom: 1.5rem;

  @include media-breakpoint-down(xl) {
    @include rfs(30px);
    margin-bottom: 1rem;
  }

  .c-text & {
    margin-bottom: 1rem;
  }
}

h3, .h3 {
  @include rfs($h3-font-size);
  margin-bottom: 1rem;

  @include media-breakpoint-down(xl) {
    @include rfs(20px);
  }
}

h4, .h4 {
  @include rfs($h4-font-size);
}

h5 , .h5 {
  @include rfs($h5-font-size);
  line-height: 1.3;

  @include media-breakpoint-down(md) {
    @include rfs(18px);
    line-height: 1.2;
  }
}

h6, .h6 {
  @include rfs($h6-font-size);
}

.h7 {
  @include rfs(14px);
  color: $dark;
}
