.modal {
  position: absolute;
  display: block;
}

.close-screen-modal {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    background-color: rgba($black, .85);
    animation: fadeIn both .3s;
  }

  .modal-content {
    animation: fadeIn both .3s;
  }

  h2 {
    @include rfs(32px);
  }
}

.modal-dialog {
  width: auto !important;
}

.modal-content {
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
}