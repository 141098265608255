.card {
  border-radius: 0;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.12);
  flex-direction: row;
  color: $base;
  text-decoration: none;
  transition: all 0.15s ease;
  position: relative;
  height: 100%;

  &::after {
    @include media-breakpoint-up(md) {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid $gray-300;
      border-right: 2px solid $gray-300;
      display: block;
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    @include media-breakpoint-only(lg) {
      width: 7px;
      height: 7px;
      right: 1rem;
    }
  }

  .out-of-stock & {
    color: $gray-300;

    &:hover,
    &:focus {
      color: $gray-300;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $base;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3);
  }

  &.highlighted {
    background-color: $primary;
  }

  &.passed {
    opacity: 0.4;
  }
}

.card-wishlist-container {
  width: 100%;
  display: flex;
  justify-content: end;

  @include media-breakpoint-up(md) {
    width: auto;
    display: block;
  }
}

.card-img {
  width: 80px;
  overflow: hidden;
  border-radius: 0;

  @include media-breakpoint-down(lg) {
    width: 125px;
  }

  @include media-breakpoint-down(sm) {
    width: 100px;
  }

  @media (max-width: 360px) {
    width: 80px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .out-of-stock & {
    opacity: 0.4;
  }

  &.fallback {
    background-color: $gray-100;

    img {
      opacity: 0;
    }
  }
}

.card-title {
  line-height: 1.3;

  @include media-breakpoint-only(lg) {
    @include rfs(14px);
    margin-bottom: 0.3rem;
  }

  @include media-breakpoint-only(md) {
    @include rfs(18px);
  }

  @include media-breakpoint-down(sm) {
    @include rfs(12px);
  }

  @media (max-width: 360px) {
    line-height: 1.1;

    @include rfs(11px);
  }
}

.card-body {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  flex: 1 1 0;
}

.card-price {
  @include media-breakpoint-only(lg) {
    @include rfs(13px);
  }

  @include media-breakpoint-down(sm) {
    @include rfs(13px);
  }

  @media (max-width: 360px) {
    @include rfs(12px);
  }

  .c-original {
    text-decoration: line-through;
  }

  .c-discount {
    color: $red;
  }
}

.card-stock {
  display: flex;

  small {
    position: relative;
    color: $base;
    display: block;
    @include rfs(14px);
    padding-left: 15px;

    @include media-breakpoint-only(lg) {
      @include rfs(12px);
      line-height: 1.1;
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.1;

      @include rfs(12px);
    }

    @media (max-width: 360px) {
      @include rfs(11px);
    }

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background-color: $red;
      border-radius: 50%;
      margin-right: 0.3rem;
      position: absolute;
      left: 0;
      top: 4px;

      @include media-breakpoint-only(lg) {
        width: 7px;
        height: 7px;
        top: 3px;
      }

      @include media-breakpoint-down(sm) {
        width: 8px;
        height: 8px;
      }

      @media (max-width: 360px) {
        width: 6px;
        height: 6px;
      }
    }
  }
}