.c-shop {
  height: 100%;

  @include media-breakpoint-down(lg) {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .c-text--intro {
    @include media-breakpoint-down(lg) {
      color: $gray-300;
      max-width: 300px;
      margin: 0 auto;

      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      text-align: center;
    }

    @media (max-width: 360px) {
      max-width: 250px;
      @include rfs(14px);
    }
  }
}

#admin .c-shop__top {
  overflow-y: scroll;
}

.c-shop__bottom {
  position: relative;
  overflow-y: hidden;

  &__inner {
    overflow-y: auto;
    height: 100%;
  }

  &::after {
    @include media-breakpoint-up(lg) {
      content: "";
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      right: 0;
      width: 100%;
      pointer-events: none;
      box-shadow: inset 0 7px 9px -7px rgba($black, 0.15);
    }
  }
}

.c-shop-list.passive .card {
  pointer-events: none;
}

.c-shop-list.inactive {
  @include media-breakpoint-up(lg) {
    position: relative;
  }
  @include media-breakpoint-down(lg) {
    padding-left: 5px;
  }

  + .c-shop__bottom {
    &::after {
      display: none;
    }
  }

  &::after {
    @include media-breakpoint-up(lg) {
      content: "";
      display: block;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: rotate(-180deg);
      width: 100%;
      height: 300px;
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .swiper-slide {
    @include media-breakpoint-down(lg) {
      box-shadow: 0 2px 9px rgba(0, 0, 0, 0.12);
      min-height: 178px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 90%;
      }
    }

    @include media-breakpoint-down(md) {
      min-height: 160px;
    }

    @include media-breakpoint-down(sm) {
      min-height: 125px;
    }
  }
}

.c-shop-list-item {
  position: relative;
  z-index: 1;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.c-shop-tabs {
  background-color: $white;
  display: flex;
  justify-content: center;
}

.c-shop-tab-item {
  padding: 1rem 0;
  position: relative;
  display: inline-block;
  color: $base;

  &:last-child {
    margin-left: 1.5rem;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 3px;
    background-color: $base;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
  }

  &.js-is-active {
    font-weight: $font-weight-bold;

    &::after {
      opacity: 1;
    }
  }
}

.c-shop-content {
  @include media-breakpoint-down(lg) {
    display: none !important;

    &.js-is-active {
      display: block !important;
    }
  }
}
