blockquote {
  position: relative;
  display: block;
  margin: 1.5rem 1rem 1.5rem 1.5rem;
  padding-left: 1.5rem;
  font-style: italic;
  @include rfs(18px);

  @include media-breakpoint-down(xs) {
    margin-left: 0;
  }

  &::before {
    background-color: $primary;
    content: '';
    width: 3px;
    height: 100%;
    display: block;

    position: absolute;
    top: 0;
    left: 0;
  }
}
