.c-logo {
  a {
    display: block;
  }

  img {
    max-width: 100%;
    height: auto;

    @include media-breakpoint-down(xs) {
      max-width: 100px;
    }
  }
}