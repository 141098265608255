.btn {
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  border: $btn-border-width;
  border-color: $border-color;
  border-radius: $border-radius;
  padding: $btn-padding-y $btn-padding-x;
  text-decoration: none;
  transition: $transition-base;
  @include rfs($btn-font-size);
  min-width: 0;

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &-primary {
    color: $gray-500;
  }

  &-secondary {
    border: 1px solid $light;
  }

  .label {
    display: none;
  }

  .label--default {
    display: inline;
  }

  &.processing {
    background-color: $primary-hover;
    border-color: $primary-hover;

    .label--default {
      display: none;
    }

    .label--processing { 
      display: inline;
    }
  }
}

.btn--round {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  line-height: 37px;
  display: block;
  text-align: center;
  padding: 0;

  &:focus,
  &:hover {
    outline: none;
  }
}

.btn-primary {
  &:focus,
  &:hover {
    background-color: $primary-hover;
  }
}

.btn-gray {
  background-color: $gray-100;

  &:focus,
  &:hover {
    background-color: $gray-200;
  }
}

.btn-cart-submit,
.btn-cart-add {
  color: $gray-500;
  font-weight: bold;
  white-space: nowrap;

  @include rfs(12px);

  @include media-breakpoint-down(sm) {
    white-space: normal;
  }

  @include media-breakpoint-between(sm, lg) {
    @include rfs(14px);
  }

  @include media-breakpoint-up(xl) {
    letter-spacing: 0.05rem;

    @include rfs(14px);
  }

  svg {
    fill: $base;
    margin-right: 7px;

    @include media-breakpoint-between(sm, lg) {
      transform: scale(1.2);
    }

    @include media-breakpoint-up(xl) {
      transform: scale(1.2);
    }
  }

  &:disabled,
  &[disabled] {
    filter: grayscale(1);
    opacity: 0.4;
  }
}

.btn-main-close {
  position: fixed;
  top: 0.75rem;
  right: 1rem;
  z-index: 11;
  background-size: 1.2rem;

  @include media-breakpoint-up(md) {
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.2rem;
  }

  @include media-breakpoint-up(xxl) {
    width: 1.6rem;
    height: 1.6rem;
    background-size: 1.4rem;
  }
}

.btn-wishlist {
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  border-radius: 30px;
  background-color: $gray-100;
  position: relative;
  display: block;
  transition: $transition-base;

  @include media-breakpoint-down(xxl) {
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
    border-radius: 24px;
  }

  @include media-breakpoint-down(xl) {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    margin-right: 0.75rem;
  }

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: $transition-base;
    width: 24px;
    height: 26px;

    @include media-breakpoint-down(xl) {
      width: 16px;
      height: 20px;
    }
  }

  .icon-inactive {
    opacity: 1;
  }

  &.active {
    .icon-active {
      opacity: 1;
    }

    .icon-inactive {
      opacity: 0;
    }
  }

  &.active {
    background-color: $primary;
    animation: animation-pulse-small 0.6s ease-out;
  }

  @media (hover: hover) {
    &:hover { 
      background-color: $primary-hover;

      .icon-active {
        opacity: 1;
      }
  
      .icon-inactive {
        opacity: 0;
      }
    }
  }
}

.article-detail .btn-wishlist {
  width: 48px;
  height: 48px;
  flex: 0 0 48px;
  border-radius: 24px;
}