/*
 *  Parapgraphs
 *  ---
 *  Default styles for paragraphs
 */

p {
  line-height: $line-height-lg;

  &:first-child {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.small p {
  @include rfs(14px);
}