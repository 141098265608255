.container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.container-fluid {
  margin-right: 0;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

.row-fluid [class*='span'] {
  min-height: inherit !important;
}